<template>
  <v-container class="pa-0 body-card">
    <div class="PaymentMethodDetail">
        <notification-popup  
        :dialog="notifSuccess"
        :contentMsg="$t(statusSuccessMessage)"
        :headerMsg="$t('Success')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('BacktoWallet')"
        imgSource="notification/success2.svg"
        typeModal="transaction"
        disableBtnNo="true"
        :onHandlerYes="buttonOke"/>


        <notification-popup 
        :dialog="notifFailed"
        :contentMsg="$t(statusFailedMessage)"
        :headerMsg="$t('failed')"
        :nameBtnNo="$t('TryAgain')"
        :nameBtnYes="$t('TryAgain')"
        imgSource="notification/failed.svg"
        typeModal="transaction"
        disableBtnNo="true"
        :onHandlerYes="buttonOkeFailed"/>

        <notification-popup 
        :dialog="dialogRemovePayment"
        :contentMsg="$t('dialogContentRemoveWallet')"
        :headerMsg="$t('dialogHeaderRemoveWallet')"
        colorheaderMsg="error--text"
        nameBtnNo="No"
        nameBtnYes="Yes"
        colorIcon = "danger"
        :onHandlerNo="cancelDialogNoRemovePayment"
        :onHandlerYes="deletePayment"/>

      <div id="spacer"></div>
      <div class="component-1 mt-n1">
        <div class="background"></div>
        <v-container class="pt-0 pb-0">
          <h4 class="h4-title">{{capitalEachWord(payment_method)}} {{$t('payment')}}</h4>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="username" 
                type="text"
                outlined
                readonly
                :isClearable="true"
                disabled
                dense
                >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0 mt-n2">
              <v-text-field
                v-model="payment_method" 
                type="text"
                outlined
                readonly
                :isClearable="true"
                dense
                disabled
              >
                <template v-slot:prepend-inner>
                  <img width="24" height="24" :src="wallet_img" >
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pt-0 pb-0 mt-n2">
              <v-text-field
                v-model="payment_number" 
                type="number"
                outlined
                :isClearable="true"
                name="WalletNumber"
                v-validate="'required|min:4|max:200'"
                :error-messages="errors.first('WalletNumber')"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container class="pb-0">
        <!-- <v-row class="onHover white-background" @click="clickShowPreview()">
          <v-col cols="12" class="pt-0 pb-0">
            <v-list-item class="pa-0">
              <v-list-item-content class="pt-1 pb-0">
                <v-list-item-title class="black--text">
                  <v-row>
                    <v-col cols="6" class="black--text">
                      <p class="mb-1">{{$t('showWallet')}}</p>
                    </v-col>
                    <v-col cols="6" class="black--text text-end">
                      <p class="mb-0 grey--text text">{{$t(anotation)}}</p>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="ml-0">
                <v-icon color="grey lighten-1"> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row> -->
        <v-row 
          v-for="item in $partnerACL.getSubMenu()"
          :key="item.title"
          class="onHover white-background"
        >
          <v-col cols="12" class="pt-0 pb-0">
            <v-list-item @click="clickShowPreview(item.name)" class="pa-0">
              <v-list-item-avatar v-if="item.icon">
                <img :src="item.icon">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="black--text">
                  {{ $t(item.title) }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon color="grey darken"> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-row class="border-bottom"> </v-row>
          </v-col>
        </v-row>
      </v-container>
      
      <v-container>
        <v-row class="onHover white-background" @click="clickDialogRemovePayment()">
          <v-col cols="12" class="pt-0 pb-0">
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title class="black--text">
                  <label class="delete-label">{{$t('deleteWallet')}}</label>
                </v-list-item-title>
              </v-list-item-content>
              
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
      
      <div class="footer-spacer"></div>
      <v-footer fixed app width="auto" color="white" elevation="3">
        <v-container class="mt-0 pt-0 mb-0 pb-0">
          <v-row>
            <v-col
              class=""
              cols="12"
              align-self="center"
            >
              <ac-button 
                :active="$partnerACL.isShowButton('save')"
                :name="$t('done')" 
                :on-click-handler="clickDone"
                :is-disabled="isButtonSaveDisabled"
                width="100%"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </div>
  </v-container>
</template>
<script>
import AcButton from '@/components/AcButton.vue'
import NotificationPopup from "@/components/NotificationPopup.vue";

  export default {
    name: 'PaymentMethodDetail',
    
    components: {NotificationPopup, AcButton  },
    data() {
      return {
        disabledBtn:"",
        dialogRemovePayment:false,
        notifSuccess:false,
        notifFailed:false,
        customer_sof_id:'',
        payment_method:'',
        payment_number:'',
        wallet_code:'',
        anotation:'',
        username:"",
        wallet_img:"",
        statusSuccessMessage:'',
        statusFailedMessage:'',
      }
    },
    computed:{
      isButtonSaveDisabled(){
        return this.payment_number == "" || this.payment_method == "" || this.errors.any();
      }
    },
    created: function () {
      // this.customer_sof_id = route.customer_sof_id
      // this.wallet_code = route.wallet_code
      // this.payment_method = route.wallet_name
      // this.payment_number = route.wallet_number
      // route.wallet_code
      // if (route.psgsof_priority == '1') {
      //   this.anotation = "default"
      // }
      // if (route.wallet_code == "01") {
      //   this.wallet_img = require('../../assets/moca.png')
      // }else if (route.wallet_code == "02") {
      //   this.wallet_img = require('../../assets/zalopay.png')
      // }else {
      //   this.wallet_img = require('../../assets/momo.png')
      // }

      this.$store.dispatch("customer/detailCustomer")
      .then((response) => {
        this.username = response.username;
      }).catch((err) => {
        console.log(err);
      })
    },
    methods:{
      cancelDialogNoRemovePayment(){
        this.dialogRemovePayment=false;
      },
      clickShowPreview(name){
        this.$router.push(
          { 
            name: name,
            params: {
              items: this.$route.params.items
            }
          });
      },
      clickDone(){
        const payload = {
            customer_sof_id: this.customer_sof_id,
            wallet_number: this.payment_number,
        };
          // console.log("data asa",payload)

        this.$store
          .dispatch("customer/editPaymentMethod", payload)
          .then((response) => {
            this.notifSuccess = true
            this.statusSuccessMessage = 'NotifSucessUpdateWallet'
          if (response.status) console.log("success");
          })
          .catch((err) => {
            // this.notifFailed = true
            // this.statusFailedMessage = 'FailedUpdateWallet'
            console.log(err);
          });

      },
      deletePayment(){
        const payload = {
          customer_sof_id: this.customer_sof_id
        };

        this.$store
        .dispatch("customer/sofPaymentDelete", payload)
        .then(() => {
           this.notifSuccess = true
           this.statusSuccessMessage = 'NotifDeleteWallet'
        // if (response.status) console.log("success");
        })
        .catch((err) => {
          // this.notifFailed = true
          // this.statusFailedMessage = 'FailedDelete'
          console.log(err);
        });
        this.cancelDialogNoRemovePayment();


      },
      clickDialogRemovePayment(){
        this.dialogRemovePayment= true
      },
      buttonOke(){
        this.notifSuccess = false
        if (this.notifSuccess == false) this.$router.push({ name: "PaymentMethodList" });
      },
      buttonOkeFailed(){
        this.notifFailed = false
      },
      capitalEachWord(words) {
        var word = words.toLowerCase().split(' ');
        for (var i = 0; i < word.length; i++) {
            word[i] = word[i].charAt(0).toUpperCase() +
            word[i].substring(1);
        }
        return word.join(' ');
      }
    },
    async mounted(){
      var paymentMethodData = null;
      if(this.$route.params.items !== undefined){
        const payload = {
            wallet_id: this.$route.params.items.wallet_info.payment_channels_id,
            wallet_number: this.$route.params.items.wallet_number,
        };
        await this.$store
          .dispatch("customer/getPaymentByCodeNumber", payload)
          .then((response) => {
            paymentMethodData = response.data
              
          })
          .catch((err) => {
            console.log(err);
          });
      }else {
        paymentMethodData = this.$store.state.customer.getPaymentMethod;
      }
      this.customer_sof_id = parseInt(paymentMethodData.customer_sof_id)
      this.wallet_code = paymentMethodData.wallet_info.wallet_code
      this.payment_method = paymentMethodData.wallet_info.wallet_name
      this.payment_number = paymentMethodData.wallet_number
      if (paymentMethodData.priority == '1') {
        this.anotation = "default"
      }

      if (paymentMethodData.wallet_info.wallet_code == "01") {
        this.wallet_img = require('../../assets/moca.png')
      }else if (paymentMethodData.wallet_info.wallet_code == "02") {
        this.wallet_img = require('../../assets/zalopay.png')
      }else {
        this.wallet_img = require('../../assets/momo.png')
      }
    }
  }
</script>

<style lang="scss" scoped>
.body-card {
  background-color: #f5fff7;
  height: 100%;
  width: 100%;
  padding-top: 0;
}
#spacer {
  background: #f5fff7;
  padding: 6px;
}
.white-background {
  background: #ffffff;
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .delete-label{
    color: #FC6276;
  }
  .component-1{
    background-color: #FFFFFF;
    width: 100%;
  }
  
  .background {
    width: 100%;
    height: 2%;
    margin-top: 1%;
    background-color: #f5fdf7;
  }

  .show-payment{
    margin-top: 2%;
    padding-bottom: 1%;
    background-color: #FFFFFF;
    width: 100%;
  }
  .label-minus {
    padding-left: -20px;
  }

  .h4-title{
    padding-top: 10px;
    padding-left: 10px;
  }
  .onHover{
    cursor: pointer;
  }
  .end{
    margin-right: 0px;
  }


  .btn-bottom{
    position: fixed;
    left: 13%;
    bottom: 0;
    width: 75%;
    margin-bottom: 5%;
  }

}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
.delete-label{
    color: #FC6276;
  }
  .component-1{
    background-color: #FFFFFF;
    width: 100%;
  }


  .background {
    width: 100%;
    height: 2%;
    margin-top: 2%;
    background-color: #f5fdf7;
  }

  .show-payment{
    margin-top: 2%;
    padding-bottom: 2%;
    background-color: #FFFFFF;
    width: 100%;
  }
  .label-minus {
    padding-left: -20px;
  }

  .h4-title{
    padding-top: 10px;
    padding-left: 10px;
  }
  .onHover{
    cursor: pointer;
  }
  .end{
    margin-right: 0px;
  }


  .btn-bottom{
    position: fixed;
    left: 5%;
    bottom:   0;
    width: 90%;
    margin-bottom: 15px;
  }
}
</style>